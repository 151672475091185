import React from 'react'
import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import { MediaItemDetails } from '../../../types/MediaItemDetails'

interface DesktopMediaItemPosterProps {
  mediaItem: MediaItemDetails
  isCurrent: boolean
}

const Poster = styled.img`
  height: 124px;
  width: 81px;

  &:hover {
    opacity: 0.8;
  }

  &.poster--current {
    opacity: 0.5;
  }
`
const DesktopMediaItemPoster = ({ mediaItem, isCurrent }: DesktopMediaItemPosterProps) => {
  const currentPoster = isCurrent ? ' poster--current' : ''
  return (
    <div className="keen-slider__slide">
      <Grid container direction="column">
        <Grid item xs={4}>
          <Poster
            src={`https://image.tmdb.org/t/p/w200${mediaItem.posterPath}`}
            alt="poster"
            className={currentPoster}
          ></Poster>
        </Grid>
      </Grid>
    </div>
  )
}

export default DesktopMediaItemPoster
