import { useMutation } from 'react-query'
import { apiClient } from '../services/apiClient'
import endpoints from '../services/apiEndpoints'

const requestLogin = async (email: string, password: string, rememberMe: boolean): Promise<number> => {
  const response = await apiClient.post<number>(
    endpoints.auth.login,
    {
      username: email,
      password,
      rememberMe
    },
    {
      withCredentials: true
    }
  )
  return response.status
}

export const useLogin = ({
  username,
  password,
  rememberMe,
  onSuccess,
  onError
}: {
  username: string
  password: string
  rememberMe: boolean
  onError: () => void
  onSuccess: () => void
}) => {
  return useMutation(
    async (): Promise<number> => {
      return await requestLogin(username, password, rememberMe)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
      },
      onError: () => {
        if (onError) {
          onError()
        }
      }
    }
  )
}
