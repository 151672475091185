import { useQuery } from 'react-query'
import { apiClient } from '../services/apiClient'
import apiEndpoints from '../services/apiEndpoints'
import { MediaItemDetails, MediaItemDetailsAggregate } from '../types/MediaItemDetails'

export interface SearchMediaOptions {
  searchTerm?: string
}

export const fetchMediaItems = async (options: SearchMediaOptions = {}): Promise<MediaItemDetails[]> => {
  const search = options.searchTerm?.replace('+', ' ') || ''

  const response = await apiClient.get<MediaItemDetailsAggregate>(apiEndpoints.search.multiSearch, {
    params: {
      query: search
    }
  })

  return response.data.items
}

export const useSearchMediaQuery = (options: SearchMediaOptions = {}) => {
  const { data, isLoading, error, isSuccess } = useQuery<MediaItemDetails[], Error>(
    ['searchMedia', JSON.stringify(options)],
    () => fetchMediaItems(options),
    {
      refetchOnWindowFocus: false
    }
  )

  return { data, isLoading, error, isSuccess }
}
