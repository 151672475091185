export type PlexAvailabilityStatus = {
  status: PlexAvailabilityStatuses
  availableChangedOn?: Date
}

export enum PlexAvailabilityStatuses {
  Unknown = 'Unknown',
  Online = 'Online',
  Offline = 'Offline',
  Maintenance = 'Maintenance'
}
