import React from 'react'
import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'
import { MediaItemDetails } from '../../../types/MediaItemDetails'
import MediaTypes from '../../../types/MediaTypes'

interface MediaItemTextProps {
  mediaItem: MediaItemDetails
}

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const getTitle = (mediaItem: MediaItemDetails) => {
  if (mediaItem.mediaType === MediaTypes.Movie) {
    return mediaItem.title
  } else {
    if (!mediaItem.seasonNumber && !mediaItem.episodeNumber) {
      return mediaItem.title
    }
    return mediaItem.tvShowTitle
  }
}

const getSubTitle = (mediaItem: MediaItemDetails) => {
  if (mediaItem.mediaType === MediaTypes.Movie) {
    return ''
  } else {
    if (mediaItem.seasonNumber && !mediaItem.episodeNumber) {
      return `Season ${mediaItem.seasonNumber}`
    }
    if (mediaItem.seasonNumber && mediaItem.episodeNumber) {
      return `S${mediaItem.seasonNumber} E${mediaItem.episodeNumber}`
    }
  }
}

const MediaItemText = ({ mediaItem }: MediaItemTextProps) => {
  return (
    <TextContainer>
      <Typography variant="body1" sx={{ paddingRight: '10px' }}>
        {getTitle(mediaItem)}
      </Typography>
      <Typography variant="body1">{getSubTitle(mediaItem)}</Typography>
    </TextContainer>
  )
}

export default MediaItemText
