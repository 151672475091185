import React from 'react'
import styled from '@emotion/styled'
import MediaTypes from '../../../types/MediaTypes'
import { getPosterUrl } from '../../../utils/images'

interface SearchListViewItemPosterProps {
  mediaItem: MediaTypes
  posterPath?: string
}

const StyledPoster = styled.img`
  height: 186px;
  width: 122px;
  position: relative;

  @media (max-width: 768px) {
    height: 123px;
    width: 81px;
  }

  @media (max-width: 500px) {
    height: 85px;
    width: 53px;
  }

  @media (max-width: 320px) {
    height: 61px;
    width: 40px;
  }
`

const SearchListViewItemPoster = ({ mediaItem, posterPath }: SearchListViewItemPosterProps) => {
  return (
    <div>
      <StyledPoster src={getPosterUrl('200', mediaItem, posterPath)}></StyledPoster>
    </div>
  )
}

export default SearchListViewItemPoster
