import React from 'react'
import styled from '@emotion/styled'
import Circle from '@mui/icons-material/Circle'
import Chip, { ChipProps } from '@mui/material/Chip'
import { SxProps } from '@mui/material/styles'
import theme from '../../data/theme'
import MediaRequestStatuses from '../../types/MediaRequestStatuses'

interface RequestStatusDisplayProps {
  status: MediaRequestStatuses
  sx?: SxProps
}

interface RequestStatusChipProps extends ChipProps {
  bgColor?: string
  textColor?: string
  iconColor?: string
  fontSize?: string
  padding?: string
}

const RequestStatusChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'bgColor' && prop !== 'textColor' && prop !== 'iconColor' && prop !== 'fontSize'
})<RequestStatusChipProps>`
  margin-bottom: 5px;
  margin-left: 5px;

  background-color: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};

  &:hover {
    background-color: ${({ bgColor }) => bgColor};
  }

  .MuiChip-icon {
    color: ${({ iconColor }) => iconColor};
  }
`

interface RequestStatusStylingMap {
  [key: string]: {
    displayName: string
    bgColor: string
    textColor: string
    iconColor: string
  }
}

const requestStatusStylingMap: RequestStatusStylingMap = {
  [MediaRequestStatuses.Requested]: {
    displayName: 'Requested',
    bgColor: theme.palette.common.requestStatusBackground,
    textColor: theme.palette.common.white,
    iconColor: theme.palette.common.requestedStatus
  },
  [MediaRequestStatuses.Approved]: {
    displayName: 'Approved',
    bgColor: theme.palette.common.requestStatusBackground,
    textColor: theme.palette.common.white,
    iconColor: theme.palette.common.approvedStatus
  },
  [MediaRequestStatuses.Rejected]: {
    displayName: 'Unavailable',
    bgColor: theme.palette.common.requestStatusBackground,
    textColor: theme.palette.common.white,
    iconColor: theme.palette.common.rejectedStatus
  },
  [MediaRequestStatuses.Available]: {
    displayName: 'Available',
    bgColor: theme.palette.common.requestStatusBackground,
    textColor: theme.palette.common.white,
    iconColor: theme.palette.common.availableStatus
  }
}

const RequestStatusDisplay = ({ status, sx }: RequestStatusDisplayProps) => {
  return (
    <RequestStatusChip
      label={requestStatusStylingMap[status].displayName}
      variant="filled"
      icon={<Circle />}
      textColor={requestStatusStylingMap[status].textColor}
      iconColor={requestStatusStylingMap[status].iconColor}
      bgColor={requestStatusStylingMap[status].bgColor}
      sx={sx}
    />
  )
}

export default RequestStatusDisplay
