import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Chip, { ChipProps } from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import { usePlexAvailabilityStatus } from '../../hooks/usePlexAvailabilityStatus'
import { PlexAvailabilityStatuses } from '../../types/PlexAvailabilityStatus'

const AvailabilityContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`

interface AvailabilityChipProps extends ChipProps {
  bgColor?: string
  textColor?: string
  fontSize?: string
  padding?: string
}

const AvailabilityChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'bgColor' && prop !== 'textColor' && prop !== 'fontSize'
})<AvailabilityChipProps>`
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};
  font-size: ${({ fontSize }) => fontSize};

  &:hover {
    background-color: ${({ bgColor }) => bgColor};
  }
`

const PlexAvailability = () => {
  const theme = useTheme()
  const [availabilityColor, setAvailabilityColor] = useState(theme.palette.common.plexOnline)
  const { data: availabilityStatus, isLoading } = usePlexAvailabilityStatus()

  useEffect(() => {
    if (isLoading || !availabilityStatus) {
      return
    }

    switch (availabilityStatus.status) {
      case PlexAvailabilityStatuses.Online:
        setAvailabilityColor(theme.palette.common.plexOnline)
        break
      case PlexAvailabilityStatuses.Offline:
      case PlexAvailabilityStatuses.Unknown:
        setAvailabilityColor(theme.palette.common.plexOffline)
        break
      case PlexAvailabilityStatuses.Maintenance:
        setAvailabilityColor(theme.palette.common.plexMaintenance)
        break
    }
  }, [availabilityStatus])

  if (isLoading || !availabilityStatus) {
    return <></>
  }

  return (
    <AvailabilityContainer>
      <Typography variant="body1">Plex: </Typography>
      <AvailabilityChip
        label={availabilityStatus.status === PlexAvailabilityStatuses.Online ? 'Running' : availabilityStatus.status}
        bgColor={availabilityColor}
        textColor={theme.palette.common.white}
        fontSize={theme.typography.body1.fontSize as string}
        padding="8px"
      ></AvailabilityChip>
    </AvailabilityContainer>
  )
}

export default PlexAvailability
