import { useQuery } from 'react-query'
import { apiClient } from '../services/apiClient'
import endpoints from '../services/apiEndpoints'
import { FactOfTheDay } from '../types/FactOfTheDay'

const fetchFactOfTheDay = async (): Promise<string> => {
  const response = await apiClient.get<FactOfTheDay>(endpoints.users.factOfTheDay, {
    withCredentials: true
  })
  return response.data.factOfTheDay
}

export const useFactOfTheDay = () => {
  const { data, isLoading, error, isSuccess } = useQuery<string, Error>('factOfTheDay', fetchFactOfTheDay, {
    refetchOnWindowFocus: false
  })

  return { data, isLoading, error, isSuccess }
}
