import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import useMediaQuery from '@mui/material/useMediaQuery'
import theme from '../../data/theme'
import { usePlexRecentlyAddedMedia } from '../../hooks/usePlexRecentlyAddedMedia'
import TitleWithDivider from '../TitleWithDivider'
import DesktopMediaItemSlider from '../mediaItemSlider/desktop/DesktopMediaItemSlider'
import MobileMediaItemSlider from '../mediaItemSlider/mobile/MobileMediaItemSlider'

const RecentlyDownloaded = () => {
  const desktopClients = useMediaQuery(theme.breakpoints.up('md'))
  const { data: mediaItems, isLoading } = usePlexRecentlyAddedMedia()
  const skeletonItems: number = desktopClients ? 11 : 4

  if (!isLoading && mediaItems?.items.length === 0) {
    return <></>
  }

  return (
    <>
      <TitleWithDivider title="Recently Downloaded" />
      {isLoading ? (
        <Grid container wrap="nowrap">
          {Array.from(Array(skeletonItems)).map((item, index) => (
            <Box key={index} sx={{ marginRight: '20px', my: 5 }}>
              <Skeleton variant="rectangular" width={90} height={140} />
              <Box sx={{ pt: 0.5 }}>
                <Skeleton variant="text" width="60%" />
              </Box>
            </Box>
          ))}
        </Grid>
      ) : desktopClients ? (
        <DesktopMediaItemSlider mediaItems={mediaItems!.items} allowRequest={false} />
      ) : (
        <MobileMediaItemSlider mediaItems={mediaItems!.items} allowRequest={false} />
      )}
    </>
  )
}

export default RecentlyDownloaded
