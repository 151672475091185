import React from 'react'
import ReactDOM from 'react-dom/client'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import Hotjar from '@hotjar/browser'
import 'keen-slider/keen-slider.min.css'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'

/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call*/

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const siteId = 3602357
const hotjarVersion = 6

Hotjar.init(siteId, hotjarVersion)

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/sendinblue/service-worker.js')
      .then((registration) => {
        // Registration was successful
        console.log('ServiceWorker registration successful with scope:', registration.scope)
      })
      .catch((err) => {
        // registration failed :(
        console.log('ServiceWorker registration failed:', err)
      })
  })
}
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
