import { useMutation } from 'react-query'
import { apiClient } from '../services/apiClient'
import endpoints from '../services/apiEndpoints'

const requestLogout = async (): Promise<number> => {
  const response = await apiClient.post<number>(endpoints.auth.logout, {
    withCredentials: true
  })
  return response.status
}

export const useLogout = ({ onSuccess, onError }: { onError: () => void; onSuccess: () => void }) => {
  return useMutation(
    async (): Promise<number> => {
      return await requestLogout()
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
      },
      onError: () => {
        if (onError) {
          onError()
        }
      }
    }
  )
}
