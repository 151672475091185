const endpoints = {
  auth: {
    login: '/auth/login',
    logout: '/auth/logout'
  },
  users: {
    me: '/users/me',
    factOfTheDay: '/users/fact-of-the-day',
    changeLog: '/users/change-log',
    updateNotifications: '/users/notifications'
  },
  search: {
    trending: '/trending',
    multiSearch: '/search/multi'
  },
  upcoming: {
    movies: '/upcoming/movies'
  },
  requests: {
    mediaRequest: '/requests',
    customMediaRequest: '/requests/custom'
  },
  admin: {
    allRequests: '/internal/requests',
    updateRequest: '/internal/requests'
  },
  plex: {
    status: '/plex/status',
    recentlyAdded: '/plex/recently-added'
  }
}

export default endpoints
