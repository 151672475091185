import { useQuery } from 'react-query'
import { apiClient } from '../services/apiClient'
import endpoints from '../services/apiEndpoints'
import { MediaItemDetailsAggregate } from '../types/MediaItemDetails'

const fetchRecentlyAdded = async (): Promise<MediaItemDetailsAggregate> => {
  const response = await apiClient.get<MediaItemDetailsAggregate>(endpoints.plex.recentlyAdded, {
    withCredentials: true
  })
  return response.data
}

export const usePlexRecentlyAddedMedia = () => {
  const { data, isLoading, error, isSuccess } = useQuery<MediaItemDetailsAggregate, Error>(
    'plexRecentlyAdded',
    () => fetchRecentlyAdded(),
    {
      refetchOnWindowFocus: false
    }
  )
  return { data, isLoading, error, isSuccess }
}
