import React from 'react'
import styled from '@emotion/styled'
import LiveTv from '@mui/icons-material/LiveTv'
import LocalMovies from '@mui/icons-material/LocalMovies'
import MediaTypes from '../../../types/MediaTypes'

interface SearchLListViewItemMediaIconProps {
  mediaType: MediaTypes
}

const StyledMediaIcon = styled.div`
  padding-right: 5px;
  padding-top: 5px;
  color: white;

  @media (min-width: 600px) {
    svg {
      font-size: 36px;
      padding-right: 10px;
      padding-top: 5px;
    }
  }
`

const renderMediaIcon = (mediaType: MediaTypes): JSX.Element => {
  switch (mediaType) {
    case MediaTypes.Movie:
      return <LocalMovies />
    case MediaTypes.TvShow:
      return <LiveTv />
    default:
      return <></>
  }
}

const SearchListViewItemMediaIcon = ({ mediaType }: SearchLListViewItemMediaIconProps) => {
  return <StyledMediaIcon>{renderMediaIcon(mediaType)}</StyledMediaIcon>
}

export default SearchListViewItemMediaIcon
