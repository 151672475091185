import React, { CSSProperties } from 'react'
import styled from '@emotion/styled'
import Divider from '@mui/material/Divider'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Typography from '@mui/material/Typography'
import { SxProps, useTheme } from '@mui/material/styles'
import { ToggleGroupItem } from '../types/ToggleGroupItem'
import SearchViewTypeSelect from './searchResult/SearchViewTypeSelect'

const TitleDivider = styled(Divider)`
  border-width: 1px;
  width: 95%;

  @media (max-width: 768px) {
    margin-left: 10px;
  }
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;

  @media (max-width: 768px) {
    margin-left: 0px;
  }
`

const TitleAndToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`

const TitleAndToggleSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`

const StyledToggleButton = styled(ToggleButton)(() => ({
  '&.MuiToggleButton-root': {
    textTransform: 'capitalize',
    width: '95px',
    borderRadius: 0,
    border: '2px solid black',
    backgroundColor: '#262626',
    '&:first-of-type': {
      borderTopLeftRadius: '40px',
      borderBottomLeftRadius: '40px'
    },
    '&:last-of-type': {
      borderTopRightRadius: '40px',
      borderBottomRightRadius: '40px'
    },
    '&:hover': {
      backgroundColor: '#DDA144'
    }
  },
  '&.Mui-selected': {
    backgroundColor: 'rgba(213, 137, 21)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(213, 137, 21)'
    }
  }
}))

interface TitleWithDividerProps {
  title: string
  toggleButtonGroupItems?: ToggleGroupItem[]
  defaultToggleGroupItemSelected?: string
  onToggleButtonGroupChange?: (event: React.MouseEvent<HTMLElement>, selectedItem: string) => void
  titleCssProperties?: CSSProperties
  displaySearchViewType?: boolean
  onSearchViewTypeButtonGroupChange?: (event: React.MouseEvent<HTMLElement>, selectedItem: string) => void
}

const TitleWithDivider = ({
  title,
  toggleButtonGroupItems,
  onToggleButtonGroupChange,
  defaultToggleGroupItemSelected,
  titleCssProperties,
  displaySearchViewType = false,
  onSearchViewTypeButtonGroupChange
}: TitleWithDividerProps) => {
  const theme = useTheme()
  const [selectedItem, setSelectedItem] = React.useState(defaultToggleGroupItemSelected || '')

  const onToggleChanged = (event: React.MouseEvent<HTMLElement>, newSelectedItem: string) => {
    setSelectedItem(newSelectedItem)
    if (onToggleButtonGroupChange) {
      onToggleButtonGroupChange(event, newSelectedItem)
    }
  }

  const onSearchTypeChanged = (event: React.MouseEvent<HTMLElement>, newSelectedItem: string) => {
    if (onSearchViewTypeButtonGroupChange) {
      onSearchViewTypeButtonGroupChange(event, newSelectedItem)
    }
  }

  return (
    <TitleContainer id="title-container" style={titleCssProperties}>
      <TitleAndToggleContainer>
        <TitleAndToggleSubContainer>
          <Typography variant="h4" sx={{ marginBottom: '12px', color: theme.palette.common.homePageTitles }}>
            {title}
          </Typography>
          {toggleButtonGroupItems && (
            <ToggleButtonGroup
              color="primary"
              value={selectedItem}
              exclusive
              onChange={onToggleChanged}
              aria-label="Platform"
              sx={{ marginBottom: '15px', marginLeft: '10px' }}
            >
              {toggleButtonGroupItems.map((item) => (
                <StyledToggleButton key={item.value} value={item.value}>
                  {item.displayName}
                </StyledToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        </TitleAndToggleSubContainer>
        {displaySearchViewType && <SearchViewTypeSelect onToggleButtonGroupChange={onSearchTypeChanged} />}
      </TitleAndToggleContainer>
      <TitleDivider sx={{ borderColor: theme.palette.common.homePageTitles }} />
    </TitleContainer>
  )
}

export default TitleWithDivider
