import MediaTypes from '../types/MediaTypes'

export const getImageUrl = (filename: string): string => {
  const baseUrl = process.env.REACT_APP_BASE_ASSETS_URL as string
  return `${baseUrl}/images/${filename}`
}

export const getDirectoryImageUrl = (directory: string, filename: string): string => {
  const baseUrl = process.env.REACT_APP_BASE_ASSETS_URL as string
  return `${baseUrl}/images/${directory}/${filename}`
}

export const getFerretImageUrl = (filename: string): string => {
  const baseUrl = process.env.REACT_APP_BASE_ASSETS_URL as string
  return `${baseUrl}/images/ferrets/${filename}`
}

export const getPosterUrl = (width: string, mediaType: MediaTypes, posterPath?: string): string => {
  if (posterPath === undefined || posterPath === null) {
    if (mediaType === MediaTypes.TvShow) {
      return 'https://cdn.ferretflix.com/images/Default_TvShow_Poster-min.png'
    } else if (mediaType == MediaTypes.Movie) {
      return 'https://cdn.ferretflix.com/images/Default_Movie_Poster-min.png'
    } else {
      return 'https://via.placeholder.com/300x450?text=No+Image'
    }
  }
  return `https://image.tmdb.org/t/p/w${width}${posterPath}`
}

export const getBackdropUrl = (mediaType: MediaTypes, backdropPath?: string): string => {
  if (backdropPath === undefined || backdropPath === null) {
    if (mediaType === MediaTypes.TvShow) {
      return 'https://cdn.ferretflix.com/images/Default_TvShow_Background-min.png'
    } else if (mediaType == MediaTypes.Movie) {
      return 'https://cdn.ferretflix.com/images/Default_Movie_Background-min.png'
    } else {
      return ''
    }
  }
  return `https://image.tmdb.org/t/p/w780/${backdropPath}`
}
