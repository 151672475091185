import axios, { AxiosRequestConfig } from 'axios'

const validateStatus = (status: number): boolean => {
  return status >= 200 && status < 400
}

export const getAxiosConfigAuth = (baseUrl: string, withCredentials = true): AxiosRequestConfig => {
  return {
    validateStatus,
    withCredentials,
    baseURL: baseUrl
  }
}

const axiosConfig = getAxiosConfigAuth(process.env.REACT_APP_BASE_API_URL as string)
export const apiClient = axios.create(axiosConfig)
