import { useQuery } from 'react-query'
import { apiClient } from '../services/apiClient'
import endpoints from '../services/apiEndpoints'
import { PlexAvailabilityStatus } from '../types/PlexAvailabilityStatus'

/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument */

const fetchPlexAvailabilityStatus = async (): Promise<PlexAvailabilityStatus> => {
  const response = await apiClient.get<PlexAvailabilityStatus>(endpoints.plex.status, {
    withCredentials: true
  })
  return response.data
}

export const usePlexAvailabilityStatus = () => {
  const { data, isLoading, error, isSuccess } = useQuery<PlexAvailabilityStatus, Error>(
    'plexAvailabilityStatus',
    fetchPlexAvailabilityStatus,
    {
      refetchOnWindowFocus: false
    }
  )

  return { data, isLoading, error, isSuccess }
}
