import { useQuery } from 'react-query'
import { apiClient } from '../services/apiClient'
import endpoints from '../services/apiEndpoints'
import { ChangeLog } from '../types/ChangeLog'

const fetchChangeLog = async (): Promise<Array<ChangeLog>> => {
  const response = await apiClient.get<Array<ChangeLog>>(endpoints.users.changeLog, {
    withCredentials: true
  })
  return response.data
}

export const useChangeLog = () => {
  const { data, isLoading, error, isSuccess } = useQuery<Array<ChangeLog>, Error>('changelog', fetchChangeLog, {
    refetchOnWindowFocus: false
  })

  return { data, isLoading, error, isSuccess }
}
