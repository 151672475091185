import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import NotificationsNoneSharpIcon from '@mui/icons-material/NotificationsNoneSharp'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useFlags } from 'launchdarkly-react-client-sdk'
import routes from '../../routes'
import { UserProfile } from '../../types/UserProfile'
import UserProfileAvatar from './UserProfileAvatar'

interface UserProfileSettingsProps {
  userProfile: UserProfile
}

const StyledBox = styled(Box)`
  @media (max-width: 960px) {
    gap: 1.5rem;
  }
  gap: 2rem;
  margin-right: 5px;
`

let settings = [
  {
    title: 'Requests',
    route: routes.requests
  },
  {
    title: 'Profile',
    route: routes.profile
  },
  {
    title: 'Logout',
    route: routes.logout
  }
]

const adminSettings = [
  {
    title: 'Admin',
    route: routes.admin
  }
]

const UserProfileSettings = ({ userProfile }: UserProfileSettingsProps) => {
  const { feShowNotificationsIcon, feCanAccessAdminPanel } = useFlags()
  const navigate = useNavigate()
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)

  if (feCanAccessAdminPanel) {
    const index = settings.findIndex((setting) => setting.title === 'Admin')

    if (index === -1) {
      settings = settings.concat(adminSettings)
    }
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = (navPath: any) => {
    setAnchorElUser(null)
    if (typeof navPath === 'string') {
      navigate(navPath)
    }
  }

  return (
    <StyledBox sx={{ display: 'flex', alignItems: 'center', flexGrow: 0, marginRight: '20px' }}>
      {feShowNotificationsIcon && (
        <Badge
          badgeContent={5}
          color="primary"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Tooltip title="Notifications" placement="bottom-end" arrow>
            <NotificationsNoneSharpIcon sx={{ color: 'white', fontSize: '28px', cursor: 'pointer' }} />
          </Tooltip>
        </Badge>
      )}
      <UserProfileAvatar avatarUrl={userProfile.avatarUrl} onClick={handleOpenUserMenu} />
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {settings.map((setting) => (
          <MenuItem key={setting.title} onClick={() => handleCloseUserMenu(setting.route)}>
            <Typography textAlign="center">{setting.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </StyledBox>
  )
}

export default UserProfileSettings
