import React from 'react'
import styled from '@emotion/styled'
import Check from '@mui/icons-material/Check'
import Close from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import Spinner from '../loading/Spinner'

const StyledActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`

interface AdminRequestActionsProps {
  onApprove: () => void
  onReject: () => void
  isUpdating: boolean
}

const AdminRequestActions = ({ onApprove, onReject, isUpdating }: AdminRequestActionsProps) => {
  const theme = useTheme()

  if (isUpdating) {
    return (
      <Box>
        <Spinner></Spinner>
      </Box>
    )
  }

  return (
    <StyledActionsContainer>
      <IconButton sx={{ backgroundColor: theme.palette.common.approvedStatus }} onClick={onApprove}>
        <Check />
      </IconButton>
      <IconButton sx={{ backgroundColor: theme.palette.common.rejectedStatus }} onClick={onReject}>
        <Close />
      </IconButton>
    </StyledActionsContainer>
  )
}

export default AdminRequestActions
