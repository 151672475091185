import React from 'react'
import { keyframes, styled } from '@mui/material/styles'
import { getFerretImageUrl } from '../../utils/images'

const rotate = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`

const StyledSpan = styled('span')`
  display: flex;
  width: 40px;
  height: 40px;
  animation: ${rotate} 2s linear infinite;
`

const MultiSpinner = styled('div')`
  display: flex;
  flex-direction: row;
`

const Spinner = () => {
  return (
    <MultiSpinner>
      <StyledSpan id="spinner">
        <img src={getFerretImageUrl('Rummy.png')} alt="Spinner" />
      </StyledSpan>
      <StyledSpan id="spinner">
        <img src={getFerretImageUrl('Cami.svg')} alt="Spinner" />
      </StyledSpan>
      <StyledSpan id="spinner">
        <img src={getFerretImageUrl('Boots.png')} alt="Spinner" />
      </StyledSpan>
    </MultiSpinner>
  )
}

export default Spinner
