import React from 'react'
import Typography from '@mui/material/Typography'

interface LargeTextComponentProps {
  maxLength?: number
  text: string
  variant?: 'body1' | 'body2' | 'h4'
  textColor?: string
}

const LargeTextComponent = ({ maxLength = 600, text, variant, textColor }: LargeTextComponentProps) => {
  const truncatedText = text.length > maxLength ? text.slice(0, maxLength) + '...' : text

  return (
    <Typography variant={variant ?? 'body1'} color={textColor ?? 'white'}>
      {truncatedText}
    </Typography>
  )
}

export default LargeTextComponent
