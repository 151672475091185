import { useQuery } from 'react-query'
import { apiClient } from '../services/apiClient'
import endpoints from '../services/apiEndpoints'
import { MediaItemDetailsAggregate } from '../types/MediaItemDetails'

const fetchTrending = async (trendingType: string): Promise<MediaItemDetailsAggregate> => {
  const endpoint = `${endpoints.search.trending}/${trendingType}`
  const response = await apiClient.get<MediaItemDetailsAggregate>(endpoint, {
    withCredentials: true
  })
  return response.data
}

export const useTrendingMedia = (trendingType: string) => {
  const { data, isLoading, error, isSuccess, refetch } = useQuery<MediaItemDetailsAggregate, Error>(
    'trendingMedia',
    () => fetchTrending(trendingType),
    {
      refetchOnWindowFocus: false
    }
  )
  return { data, isLoading, error, isSuccess, refetch }
}
