import { useMutation } from 'react-query'
import { AxiosError } from 'axios'
import { apiClient } from '../services/apiClient'
import endpoints from '../services/apiEndpoints'
import { ApiError } from '../types/ApiError'
import SendMediaRequest from '../types/SendMediaRequest'
import UpdateMediaRequest from '../types/UpdateMediaRequest'

const updateMediaRequest = async (mediaRequest: UpdateMediaRequest): Promise<number> => {
  const response = await apiClient.put<number>(endpoints.admin.updateRequest, mediaRequest, {
    withCredentials: true
  })
  return response.status
}

export const useUpdateMediaRequest = ({
  onSuccess,
  onError
}: {
  onError: (message: string) => void
  onSuccess: () => void
}) => {
  return useMutation(
    async (mediaRequest: UpdateMediaRequest): Promise<number> => {
      return await updateMediaRequest(mediaRequest)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
      },
      onError: (error: AxiosError) => {
        const errorDetails = error.response!.data as ApiError
        if (onError) {
          onError(errorDetails.errors[0].message)
        }
      }
    }
  )
}
