import { useMutation } from 'react-query'
import { AxiosError } from 'axios'
import { apiClient } from '../services/apiClient'
import endpoints from '../services/apiEndpoints'
import { ApiError } from '../types/ApiError'
import SendCustomMediaRequest from '../types/SendCustomMediaRequest'

const sendCustomMediaRequest = async (mediaRequest: SendCustomMediaRequest): Promise<number> => {
  const response = await apiClient.post<number>(endpoints.requests.customMediaRequest, mediaRequest, {
    withCredentials: true
  })
  return response.status
}

export const useCustomSendMediaRequest = ({
  onSuccess,
  onError
}: {
  onError: (message: string) => void
  onSuccess: () => void
}) => {
  return useMutation(
    async (mediaRequest: SendCustomMediaRequest): Promise<number> => {
      return await sendCustomMediaRequest(mediaRequest)
    },
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess()
        }
      },
      onError: (error: AxiosError) => {
        const errorDetails = error.response!.data as ApiError
        if (onError) {
          onError(errorDetails.errors[0].message)
        }
      }
    }
  )
}
