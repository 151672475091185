import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { LDClient } from 'launchdarkly-js-client-sdk'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import LoadingFerret from '../components/loading/LoadingFerret'
import AppNavBar from '../components/navBar/AppNavBar'
import { useUserProfile } from '../hooks/useUserProfile'
import routes from '../routes'

const AuthenticatedRoute = ({
  Component,
  targetRoute,
  ldClient
}: {
  Component: React.JSX.Element
  targetRoute?: string
  ldClient?: LDClient
}) => {
  const retries = targetRoute === routes.root ? 0 : 3
  const { data: userProfile, isLoading, retriesRemaining } = useUserProfile(retries)
  const isAuthenticatedUser = userProfile?.userId !== undefined
  const navigate = useNavigate()
  const [isPageLoading, setIsPageLoading] = useState(isLoading)

  useEffect(() => {
    if (isAuthenticatedUser && ldClient) {
      ldClient
        .identify({
          kind: 'user',
          key: userProfile.userId,
          name: userProfile.username,
          email: userProfile.email
        })
        .catch(() => {
          navigate(routes.error)
        })
    }
  }, [isAuthenticatedUser, ldClient, userProfile, navigate])

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setIsPageLoading(false)
      }, 1000)
    }
  }, [isLoading])

  if (isPageLoading) {
    return <LoadingFerret />
  }

  if (isAuthenticatedUser) {
    return <AppNavBar userProfile={userProfile}>{React.cloneElement(Component, { userProfile })}</AppNavBar>
  } else {
    if (retriesRemaining > 0) {
      return <p>Loading</p>
    } else {
      return <Navigate to={routes.login} />
    }
  }
}

export default withLDConsumer()(AuthenticatedRoute)
