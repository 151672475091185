import React, { useState } from 'react'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useKeenSlider } from 'keen-slider/react'
import theme from '../../data/theme'
import { MediaItemDetails } from '../../types/MediaItemDetails'
import RequestButtonType from '../../types/RequestButtonType'
import RequestStatusContainer from '../requestStatus/RequestStatusContainer'
import SliderArrow from './SliderArrow'

const BackgroundContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  padding: 15px;
`

const StyledEmbedContainer = styled.div`
  border-radius: 10px;

  &:hover {
    opacity: 0.7;
  }
`

interface YouTubeMediaItemSliderProps {
  movies: MediaItemDetails[]
}

/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return */
const YouTubeMediaItemSlider = ({ movies }: YouTubeMediaItemSliderProps) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const isLargeDevice = useMediaQuery(theme.breakpoints.up('md'))
  const [loaded, setLoaded] = useState(false)
  const [ref, instanceRef] = useKeenSlider<HTMLDivElement>({
    loop: false,
    slides: {
      perView: isLargeDevice ? 2.2 : 1.2,
      spacing: 8
    },
    drag: true,
    dragSpeed: isLargeDevice ? 0.3 : 0.9,
    mode: 'snap',
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    }
  })

  return (
    <BackgroundContainer>
      <div ref={ref} className="keen-slider">
        {movies.map((mediaItem, index) => (
          <div key={mediaItem.id} className="keen-slider__slide">
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <StyledEmbedContainer>
                  <LiteYouTubeEmbed id={mediaItem.youTubeKey} title={mediaItem.title} />
                </StyledEmbedContainer>
              </Grid>
              <Grid container item direction="row" justifyContent="space-between" alignItems="center">
                <Grid item sx={{ marginTop: '10px' }} xs={isLargeDevice ? 7 : 6}>
                  <Typography variant="body1">{mediaItem.title}</Typography>
                </Grid>
                <Grid
                  item
                  sx={{ marginTop: '10px', marginRight: '10px', display: 'flex', justifyContent: 'flex-end' }}
                  xs={isLargeDevice ? 4 : 5}
                >
                  {' '}
                  {isLargeDevice ? (
                    <RequestStatusContainer
                      mediaItem={mediaItem}
                      buttonType={RequestButtonType.CHIP}
                    ></RequestStatusContainer>
                  ) : (
                    <RequestStatusContainer
                      mediaItem={mediaItem}
                      buttonType={RequestButtonType.ICONBUTTON}
                      buttonSx={{
                        top: '0px',
                        left: '0px',
                        bottom: '0px',
                        right: '0px',
                        position: 'initial'
                      }}
                    ></RequestStatusContainer>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        ))}
        {loaded && instanceRef.current && (
          <>
            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return */}
            <SliderArrow
              left
              onClick={(e: any) => e.stopPropagation() || instanceRef.current?.prev()}
              disabled={currentSlide === 0}
            />

            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return */}
            <SliderArrow
              onClick={(e: any) => e.stopPropagation() || instanceRef.current?.next()}
              disabled={currentSlide === instanceRef.current.track.details.slides.length - 2}
            />
          </>
        )}
      </div>
    </BackgroundContainer>
  )
}

export default YouTubeMediaItemSlider
