import React, { MutableRefObject } from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { KeenSliderInstance, KeenSliderPlugin, useKeenSlider } from 'keen-slider/react'
import { MediaItemDetails } from '../../../types/MediaItemDetails'
import MobileMediaItemPoster from './MobileMediaItemPoster'

function ThumbnailPlugin(mainRef: MutableRefObject<KeenSliderInstance | null>): KeenSliderPlugin {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove('active')
      })
    }
    function addActive(idx: number) {
      slider.slides[idx].classList.add('active')
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener('click', () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx)
        })
      })
    }

    slider.on('created', () => {
      if (!mainRef.current) return
      addActive(slider.track.details.rel)
      addClickEvents()
      mainRef.current.on('animationStarted', (main) => {
        removeActive()
        const next = main.animator.targetIdx || 0
        addActive(main.track.absToRel(next))
        slider.moveToIdx(Math.min(slider.track.details.maxIdx, next))
      })
    })
  }
}

const SliderContainer = styled.div`
  padding: 15px;
`

interface MediaItemSliderProps {
  mediaItems: MediaItemDetails[]
  allowRequest?: boolean
}

const MediaItemSlider = ({ mediaItems, allowRequest = false }: MediaItemSliderProps) => {
  const theme = useTheme()
  const extraSmallClient = useMediaQuery(theme.breakpoints.only('xs'))

  const itemsPerView = extraSmallClient ? 2.4 : 5.4

  const [, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0
  })
  const [thumbnailRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      slides: {
        perView: itemsPerView,
        spacing: 0
      },
      drag: true,
      dragSpeed: 0.9,
      mode: 'free'
    },
    [ThumbnailPlugin(instanceRef)]
  )

  return (
    <SliderContainer style={{ background: theme.palette.common.sliderBackground }}>
      <div ref={thumbnailRef} className="keen-slider thumbnail">
        {mediaItems.map((mediaItem, index) => (
          <MobileMediaItemPoster key={index} mediaItem={mediaItem} allowRequest={allowRequest}></MobileMediaItemPoster>
        ))}
      </div>
    </SliderContainer>
  )
}

export default MediaItemSlider
