import { useQuery } from 'react-query'
import { apiClient } from '../services/apiClient'
import endpoints from '../services/apiEndpoints'
import { UserProfile } from '../types/UserProfile'

const fetchUserProfile = async (): Promise<UserProfile> => {
  const response = await apiClient.get<UserProfile>(endpoints.users.me, {
    withCredentials: true
  })
  return response.data
}

export const useUserProfile = (retries = 3) => {
  const { data, isLoading, error, isSuccess, failureCount, refetch } = useQuery<UserProfile, Error>(
    'userProfile',
    fetchUserProfile,
    {
      retry: retries,
      retryDelay: 1000,
      enabled: true,
      refetchOnWindowFocus: false
    }
  )

  const retriesRemaining = retries - failureCount

  if (error?.message === 'Unauthorized' && retriesRemaining > 0) {
    setTimeout(() => {
      void refetch()
    }, 1000)
  }

  return { data, isLoading, error, isSuccess, retriesRemaining }
}
