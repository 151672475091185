import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import LargeTextComponent from '../../LargeText'

export interface SearchGridViewItemTitleProps {
  title: string
  year?: number
}

const SearchGridViewItemTitle = ({ title, year }: SearchGridViewItemTitleProps) => {
  const theme = useTheme()

  return (
    <Grid container item direction="column" style={{ height: '100%' }}>
      <Grid item xs>
        <LargeTextComponent
          textColor={theme.palette.common.searchGridPosterTitle}
          variant="body2"
          text={title}
          maxLength={28}
        />
      </Grid>
      {year && (
        <Grid item xs>
          <Typography variant="body1" color={theme.palette.common.searchGridPosterTitle}>
            {year ? `(${year})` : ''}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default SearchGridViewItemTitle
