import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import content from '../../data/content'
import theme from '../../data/theme'
import routes from '../../routes'
import { SearchForm } from '../../types/SearchForm'
import { getImageUrl } from '../../utils/images'
import TextInput from '../input/TextInput'
import LoadingButton from '../loading/LoadingButton'

const BackgroundContainer = styled.div``

const FerretBackgroundImage = styled.img`
  width: 438px;
  height: 100%;
`

const FerretFlixTitleImage = styled.img`
  margin-top: 4px;
  margin-left: 4px;
  height: 42px;
  width: 187px;
  margin-bottom: 0px;
`
const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-left: 20px;
`

const SearchTextInput = styled(TextInput)`
  border-radius: 20px;
`

const FerretFactContainer = styled.div`
  margin-left: 8px;
  margin-top: 18px;
`
interface SearchMediaProps {
  factOfTheDay?: string | undefined
}

const componentContent = content.pages.home.search

const SearchMedia = ({ factOfTheDay }: SearchMediaProps) => {
  const navigate = useNavigate()
  const isLargeDevice = useMediaQuery(theme.breakpoints.up('md'))
  const [searchInput, setSearchInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const handleSearchSubmit = () => {
    setIsLoading(true)
    setTimeout(() => {
      const uriEncodedInput = encodeURIComponent(searchInput)
      navigate(`${routes.searchResults.partial}/${uriEncodedInput}`)
      setIsLoading(false)
    }, 1000)
  }

  const form = useForm<SearchForm>({
    mode: 'onChange',
    defaultValues: {
      searchTerm: ''
    }
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = form

  return (
    <BackgroundContainer style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <Grid container direction="row">
        <Grid item xs={isLargeDevice ? 7 : 12}>
          <Grid container item direction="column" justifyContent="flex-end">
            <Grid item xs={12}>
              <FerretFlixTitleImage
                src={getImageUrl('FerretFlix_Title-min.png')}
                alt="FerretFlix logo"
              ></FerretFlixTitleImage>
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit(handleSearchSubmit)}>
                <SearchContainer>
                  <SearchTextInput
                    register={register}
                    name="searchQuery"
                    showLabel={false}
                    placeholder={componentContent.placeholder}
                    error={errors.searchTerm}
                    value={searchInput}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value)}
                    validation={{
                      required: componentContent.errors.searchTerm.required
                    }}
                  ></SearchTextInput>
                  <LoadingButton
                    id="search-button"
                    onClick={handleSearchSubmit}
                    isLoading={isLoading}
                    sx={{ marginTop: '10px', marginRight: '8px' }}
                    disabled={searchInput === ''}
                  >
                    Search
                  </LoadingButton>
                </SearchContainer>
              </form>
            </Grid>
            <Grid item xs={12}>
              <FerretFactContainer>
                <Typography variant="body1">{`${content.pages.home.search.factOfTheDayPrefix} ${
                  factOfTheDay || ''
                }`}</Typography>
              </FerretFactContainer>
            </Grid>
          </Grid>
        </Grid>
        {isLargeDevice && (
          <Grid item xs={5}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <FerretBackgroundImage
                src={getImageUrl('Search_Background_Final.png')}
                alt="Ferret Title"
              ></FerretBackgroundImage>
            </div>
          </Grid>
        )}
      </Grid>
    </BackgroundContainer>
  )
}

export default SearchMedia
