import React from 'react'
import styled from '@emotion/styled'
import Tooltip from '@mui/material/Tooltip'
import routes from '../../routes'
import { getImageUrl } from '../../utils/images'

const StyledLogo = styled.img`
  height: 53px;
  width: 90px;
  margin-top: 5px;
`

const NavBarLogo = () => {
  return (
    <Tooltip title="Home" placement="right" arrow>
      <a href={routes.home}>
        <StyledLogo src={getImageUrl('Logo-min.png')} alt="FerretFlix logo" />
      </a>
    </Tooltip>
  )
}

export default NavBarLogo
