import styled from '@emotion/styled'
import { MaterialDesignContent } from 'notistack'

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#56C568'
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#EB5757'
  }
}))

export default StyledMaterialDesignContent
