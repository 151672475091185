import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Toolbar from '@mui/material/Toolbar'
import { useTheme } from '@mui/material/styles'
import theme from '../../data/theme'
import { UserProfile } from '../../types/UserProfile'
import NavBarLogo from './NavBarLogo'
import PlexAvailability from './PlexAvailability'
import UserProfileSettings from './UserProfileSettings'

const StyledComponentContent = styled.div`
  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: 0px;
    padding-bottom: 15px;
  }

  padding-bottom: 15px;
`
interface AppNavBarProps {
  userProfile: UserProfile
  children: ReactNode
}

const AppNavBar = ({ userProfile, children }: AppNavBarProps) => {
  const theme = useTheme()

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <NavBarLogo />
            </Grid>
            <Grid item>
              <PlexAvailability />
            </Grid>
            {/*<NavPages />*/}
            <Grid item>
              <UserProfileSettings userProfile={userProfile} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Container
        id="app-page-container"
        sx={{ backgroundColor: theme.palette.common.containerBody, paddingTop: '15px' }}
      >
        <StyledComponentContent
          id="app-page-sub-container"
          style={{ backgroundColor: theme.palette.common.containerBody }}
        >
          {children}
        </StyledComponentContent>
      </Container>
    </>
  )
}

export default AppNavBar
