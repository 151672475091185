import React, { MutableRefObject, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { KeenSliderInstance, KeenSliderPlugin, useKeenSlider } from 'keen-slider/react'
import { MediaItemDetails } from '../../../types/MediaItemDetails'
import { getBackdropUrl } from '../../../utils/images'
import SliderArrow from '../../youtubeSlider/SliderArrow'
import DesktopMediaItemDetail from './DesktopMediaItemDetail'
import DesktopMediaItemPoster from './DesktopMediaItemPoster'

/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return */
function ThumbnailPlugin(mainRef: MutableRefObject<KeenSliderInstance | null>): KeenSliderPlugin {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove('active')
      })
    }
    function addActive(idx: number) {
      slider.slides[idx].classList.add('active')
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener('click', () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx)
        })
      })
    }

    slider.on('created', () => {
      if (!mainRef.current) return
      addActive(slider.track.details.rel)
      addClickEvents()
      mainRef.current.on('animationStarted', (main) => {
        removeActive()
        const next = main.animator.targetIdx || 0
        addActive(main.track.absToRel(next))
        slider.moveToIdx(Math.min(slider.track.details.maxIdx, next))
      })
    })
  }
}

const SliderContainer = styled.div`
  padding: 15px;
`

interface MediaItemSliderProps {
  mediaItems: MediaItemDetails[]
  allowRequest?: boolean
}

const MediaItemSlider = ({ mediaItems, allowRequest }: MediaItemSliderProps) => {
  const theme = useTheme()
  const isLargeDevice = useMediaQuery(theme.breakpoints.up('md'))
  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    }
  })
  const [thumbnailRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      slides: {
        perView: 8.25,
        spacing: 0
      },
      drag: true,
      dragSpeed: isLargeDevice ? 0.3 : 0.9,
      mode: 'free'
    },
    [ThumbnailPlugin(instanceRef)]
  )

  useEffect(() => {
    if (instanceRef.current) {
      instanceRef.current?.update()
    }
  }, [mediaItems, instanceRef])

  return (
    <SliderContainer style={{ background: theme.palette.common.sliderBackground }}>
      <div ref={sliderRef} className="keen-slider">
        {mediaItems.map((mediaItem, index) => (
          <DesktopMediaItemDetail
            key={index}
            mediaItem={mediaItem}
            backdropPath={getBackdropUrl(mediaItem.mediaType, mediaItem.backdropPath)}
            allowRequest={allowRequest}
          ></DesktopMediaItemDetail>
        ))}
        {loaded && instanceRef.current && (
          <>
            <SliderArrow
              left
              onClick={(e: any) => e.stopPropagation() || instanceRef.current?.prev()}
              disabled={currentSlide === 0}
            />

            <SliderArrow
              onClick={(e: any) => e.stopPropagation() || instanceRef.current?.next()}
              disabled={currentSlide === instanceRef.current.track.details.slides.length - 1}
            />
          </>
        )}
      </div>

      <div ref={thumbnailRef} className="keen-slider thumbnail">
        {mediaItems.map((mediaItem, idx) => (
          <DesktopMediaItemPoster
            key={mediaItem.id}
            mediaItem={mediaItem}
            isCurrent={currentSlide === idx}
          ></DesktopMediaItemPoster>
        ))}
      </div>
    </SliderContainer>
  )
}

export default MediaItemSlider
