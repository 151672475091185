import React from 'react'
import { useTheme } from '@mui/material/styles'
import { getImageUrl } from '../utils/images'

const LoginBackground = () => {
  const theme = useTheme()

  return (
    <picture data-cy="login-background-picture">
      <source
        media={`(min-width: ${theme.breakpoints.values.xl}px)`}
        srcSet={`${getImageUrl('FerretFlix_Login_3440x1440-min.png')} 2x`}
      />
      <source
        media={`(min-width: ${theme.breakpoints.values.lg}px)`}
        srcSet={`${getImageUrl('FerretFlix_Login_2560x1440-min.png')} 2x`}
      />
      <source
        media={`(min-width: ${theme.breakpoints.values.md}px)`}
        srcSet={`${getImageUrl('FerretFlix_Login_1920x1080-min.png')} 2x`}
      />
      <source
        media={`(min-width: ${theme.breakpoints.values.sm}px)`}
        srcSet={`${getImageUrl('FerretFlix_Login_1024x1366-min.png')} 2x`}
      />
      <source
        media={`(min-width: ${theme.breakpoints.values.xs}px)`}
        srcSet={`${getImageUrl('FerretFlix_Login_494x896-min.png')} 2x`}
      />
      <img
        src={getImageUrl('FerretFlix_Login_3440x1440-min.png')}
        alt="Login Background"
        style={{ objectFit: 'cover', height: '100%', width: '100%' }}
      />
    </picture>
  )
}

export default LoginBackground
