import React from 'react'
import { Route, Routes } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { SnackbarProvider } from 'notistack'
import AuthenticatedRoute from './auth/AuthenticatedRoute'
import StyledMaterialDesignContent from './data/StyledSnackbarContent'
import theme from './data/theme'
import AdminPage from './pages/AdminPage'
import ErrorPage from './pages/ErrorPage'
import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'
import LogoutPage from './pages/LogoutPage'
import NotFoundPage from './pages/NotFoundPage'
import ProfilePage from './pages/ProfilePage'
import RequestsPage from './pages/RequestsPage'
import SearchMediaResultsPage from './pages/SearchMediaResultsPage'
import routes from './routes'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent
        }}
        autoHideDuration={3000}
      >
        <CssBaseline>
          <Routes>
            <Route path={routes.login} element={<LoginPage />} />
            <Route path={routes.error} element={<ErrorPage />} />
            <Route
              element={<AuthenticatedRoute Component={<HomePage />} targetRoute={routes.root} />}
              path={routes.root}
            />
            <Route element={<AuthenticatedRoute Component={<HomePage />} />} path={routes.home} />\
            <Route
              element={<AuthenticatedRoute Component={<SearchMediaResultsPage />} />}
              path={routes.searchResults.route}
            />
            <Route element={<AuthenticatedRoute Component={<RequestsPage />} />} path={routes.requests} />\
            <Route element={<AuthenticatedRoute Component={<ProfilePage />} />} path={routes.profile} />\
            <Route element={<AuthenticatedRoute Component={<AdminPage />} />} path={routes.admin} />\
            <Route element={<AuthenticatedRoute Component={<LogoutPage />} />} path={routes.logout} />\
            <Route path={routes.wildcard} element={<NotFoundPage />} />
          </Routes>
        </CssBaseline>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LD_CLIENT_ID as string,
  options: {
    bootstrap: 'localStorage'
  }
})(App)
