import React from 'react'
import styled from '@emotion/styled'
import MediaTypes from '../../../types/MediaTypes'
import { getPosterUrl } from '../../../utils/images'

interface SearchListViewItemPosterProps {
  mediaType: MediaTypes
  posterPath?: string
  children?: React.ReactNode
}

const PosterContainer = styled.div`
  position: relative;
  display: inline-block; // Allows the container to size according to the image
`

const StyledPoster = styled.img`
  height: 232px;
  width: 152px;
  margin-top: 10px;
  margin-left: 11px;
  position: relative;

  @media (max-width: 1180px) {
    height: 232px;
    width: 152px;
    margin: 10px 10px 10px 10px;
  }

  @media (max-width: 960px) {
    height: 232px;
    width: 135px;
    margin: 10px 10px 10px 10px;
  }
`

const ChildrenOverlay = styled.div`
  position: absolute;
  top: 95px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center; // Centers children vertically
  justify-content: center; // Centers children horizontally
`

const SearchGridViewItemPoster = ({ mediaType, posterPath, children }: SearchListViewItemPosterProps) => {
  return (
    <PosterContainer>
      <StyledPoster src={getPosterUrl('200', mediaType, posterPath)} />
      <ChildrenOverlay>{children}</ChildrenOverlay>
    </PosterContainer>
  )
}

export default SearchGridViewItemPoster
