import React, { CSSProperties, useState } from 'react'
import { SxProps } from '@mui/material/styles'
import { MediaItemDetails } from '../../types/MediaItemDetails'
import MediaRequestStatuses from '../../types/MediaRequestStatuses'
import RequestButtonType from '../../types/RequestButtonType'
import RequestStatusButton from './RequestStatusButton'
import RequestStatusDisplay from './RequestStatusDisplay'

export interface RequestStatusContainerProps {
  mediaItem: MediaItemDetails
  buttonType: RequestButtonType
  buttonSx?: SxProps
  displaySx?: SxProps
  spinnerSx?: CSSProperties
}
const RequestStatusContainer = ({
  mediaItem,
  buttonType,
  buttonSx,
  displaySx,
  spinnerSx
}: RequestStatusContainerProps) => {
  const [currentStatus, setCurrentStatus] = useState<MediaRequestStatuses | undefined>(mediaItem.requestStatus)

  const handleRequestChanged = () => {
    setCurrentStatus(MediaRequestStatuses.Requested)
  }

  return (
    <>
      {currentStatus ? (
        <RequestStatusDisplay status={currentStatus} sx={displaySx} />
      ) : (
        <RequestStatusButton
          buttonType={buttonType}
          mediaItem={mediaItem}
          onRequestChanged={handleRequestChanged}
          buttonSx={buttonSx}
          spinnerSx={spinnerSx}
        />
      )}
    </>
  )
}

export default RequestStatusContainer
