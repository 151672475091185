import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import useMediaQuery from '@mui/material/useMediaQuery'
import theme from '../../data/theme'
import { useTrendingMedia } from '../../hooks/useTrendingMedia'
import { ToggleGroupItem } from '../../types/ToggleGroupItem'
import TitleWithDivider from '../TitleWithDivider'
import DesktopMediaItemSlider from '../mediaItemSlider/desktop/DesktopMediaItemSlider'
import MobileMediaItemSlider from '../mediaItemSlider/mobile/MobileMediaItemSlider'

const toggleFilterItems: ToggleGroupItem[] = [
  {
    value: 'all',
    displayName: 'All'
  },
  {
    value: 'movies',
    displayName: 'Movies'
  },
  {
    value: 'tv',
    displayName: 'TV Shows'
  }
]

const Recommendations = () => {
  const desktopClients = useMediaQuery(theme.breakpoints.up('md'))
  const [selectedMediaItem, setSelectedMediaItem] = React.useState<string>('all')
  const {
    data: mediaItems,
    isLoading: isAllTrendingLoading,
    refetch: refetchTrendingMedia
  } = useTrendingMedia(selectedMediaItem)

  const [isLoading, setIsLoading] = useState(true)

  const handleFilterChange = (event: React.MouseEvent<HTMLElement>, newFilter: string) => {
    setSelectedMediaItem(newFilter)
  }

  useEffect(() => {
    setIsLoading(true)
    refetchTrendingMedia()
      .then(() => {
        setTimeout(() => {
          setIsLoading(false)
        }, 500)
      })
      .catch(() => setIsLoading(false))
  }, [selectedMediaItem])

  const skeletonItems: number = desktopClients ? 11 : 4

  return (
    <>
      <TitleWithDivider
        title="Ferretmendations"
        toggleButtonGroupItems={toggleFilterItems}
        onToggleButtonGroupChange={handleFilterChange}
        defaultToggleGroupItemSelected="all"
      />
      {isAllTrendingLoading || isLoading ? (
        <Grid container wrap="nowrap">
          {Array.from(Array(skeletonItems)).map((item, index) => (
            <Box key={index} sx={{ marginRight: '20px', my: 5 }}>
              <Skeleton variant="rectangular" width={90} height={140} />
              <Box sx={{ pt: 0.5 }}>
                <Skeleton variant="text" width="60%" />
              </Box>
            </Box>
          ))}
        </Grid>
      ) : desktopClients ? (
        <DesktopMediaItemSlider mediaItems={mediaItems!.items} />
      ) : (
        <MobileMediaItemSlider mediaItems={mediaItems!.items} allowRequest={true} />
      )}
    </>
  )
}

export default Recommendations
