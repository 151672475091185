import React from 'react'
import styled from '@emotion/styled'

interface SliderArrowProps {
  disabled: boolean
  left?: boolean
  onClick: (e: any) => void
}

const StyledArrowIcon = styled.svg`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: #fff;
  cursor: pointer;

  &.arrow--left {
    left: 0px;
  }

  &.arrow--right {
    left: auto;
    right: 0px;
  }

  &.arrow--disabled {
    fill: rgba(255, 255, 255, 0.5);
  }
`

const SliderArrow = (props: SliderArrowProps) => {
  const disabled = props.disabled ? ' arrow--disabled' : ''
  return (
    <StyledArrowIcon
      onClick={props.onClick}
      className={`arrow ${props.left ? 'arrow--left' : 'arrow--right'} ${disabled}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />}
      {!props.left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />}
    </StyledArrowIcon>
  )
}

export default SliderArrow
