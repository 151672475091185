import React from 'react'
import { FieldError, FieldValues, UseFormRegister } from 'react-hook-form'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { SxProps, Theme } from '@mui/material/styles'
import theme from '../../data/theme'
import ErrorText from './InputError'

interface Props {
  register: UseFormRegister<FieldValues>
  name: string
  label?: string
  type?: string
  autocomplete?: string
  optional?: boolean
  showLabel?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: string
  validation?: { [key: string]: string | boolean | {} }
  error?: FieldError
  placeholder?: string
  showErrorIcon?: boolean
  disabled?: boolean
  sx?: SxProps<Theme>
}

const TextInput: React.FC<Props> = ({
  register,
  name,
  label,
  type,
  autocomplete,
  onChange,
  value,
  validation = {},
  error,
  placeholder = '',
  optional,
  showLabel = true,
  showErrorIcon,
  disabled = false,
  sx
}) => {
  return (
    <FormControl sx={{ width: '100%' }}>
      {showLabel && (
        <FormLabel htmlFor={name}>
          {optional ? (
            <>
              {label}{' '}
              <Typography variant="inputLabel" sx={{ color: theme.typography.inputLabel }}>
                (optional)
              </Typography>{' '}
            </>
          ) : (
            label
          )}
        </FormLabel>
      )}

      <TextField
        {...register(name, validation)}
        id={name}
        type={type}
        autoComplete={autocomplete}
        data-cy={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        error={!!error}
        aria-invalid={error ? 'true' : 'false'}
        disabled={disabled}
        variant="outlined"
        margin="normal"
        sx={sx}
      />
      {error && (
        <ErrorText id={name} showIcon={showErrorIcon}>
          {error.message}
        </ErrorText>
      )}
    </FormControl>
  )
}

export default TextInput
