import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'
import routes from '../routes'
import { getImageUrl } from '../utils/images'
import LoadingButton from './loading/LoadingButton'

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  height: 100vh;

  @media (max-width: 1024px) {
    height: 100vh;
  }

  @media (max-width: 896px) {
    height: 100%;
  }

  @media (max-width: 820px) {
    height: 100vh;
  }

  @media (max-width: 768px) {
    height: 100vh;
  }

  @media (max-width: 667px) {
    height: 100%;
  }

  Tablet and smaller @media (max-width: 414px) {
    height: 100vh;
  }
`

const StyledMazeImage = styled.img`
  width: 250px;
  height: 250px;
`

const StyledDescriptionText = styled(Typography)`
  margin-bottom: 25px;
  width: 50%;
  padding-left: 10px;
  padding-right: 10px;

  //Tablet and smaller
  @media (max-width: 1024px) {
    padding-top: 10px;
    width: 75%;
  }
`

const FerretMaze = () => {
  const navigate = useNavigate()

  const handleHomeClicked = () => {
    navigate(routes.root)
  }

  return (
    <NotFoundContainer>
      <Typography variant="h1" sx={{ marginBottom: '25px' }}>
        Hmm... ?
      </Typography>
      <StyledDescriptionText variant="body2">
        The ferrets can’t seem to find that page at the moment. They are working hard to try and find it. Until then
        lets go back to the home page and start again.
      </StyledDescriptionText>
      <StyledMazeImage src={getImageUrl('Not_Found_Maze.gif')} alt="Not Found Indicator"></StyledMazeImage>
      <div style={{ marginBottom: '25px' }}>
        <LoadingButton sx={{ marginTop: '25px' }} id="go-home" onClick={handleHomeClicked}>
          Home Page
        </LoadingButton>
      </div>
    </NotFoundContainer>
  )
}

export default FerretMaze
