import { useQuery } from 'react-query'
import { apiClient } from '../services/apiClient'
import apiEndpoints from '../services/apiEndpoints'
import { MediaItemDetails, MediaItemDetailsAggregate } from '../types/MediaItemDetails'

export const fetchMyRequests = async (): Promise<MediaItemDetails[]> => {
  const response = await apiClient.get<MediaItemDetailsAggregate>(apiEndpoints.requests.mediaRequest)

  return response.data.items
}

export const useMyRequests = () => {
  const { data, isLoading, error, isSuccess, refetch } = useQuery<MediaItemDetails[], Error>(
    ['myRequests'],
    () => fetchMyRequests(),
    {
      refetchOnWindowFocus: false
    }
  )

  return { data, isLoading, error, isSuccess, refetch }
}
