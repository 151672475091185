import React from 'react'
import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import { MediaItemDetails } from '../../../types/MediaItemDetails'
import RequestButtonType from '../../../types/RequestButtonType'
import RequestStatusContainer from '../../requestStatus/RequestStatusContainer'
import MediaItemText from './MediaItemText'

interface MobileMediaItemPosterProps {
  mediaItem: MediaItemDetails
  allowRequest?: boolean
}

const Poster = styled.img`
  height: 186px;
  width: 122px;
  position: relative;
`

const MobileMediaItemPoster = ({ mediaItem, allowRequest = true }: MobileMediaItemPosterProps) => {
  return (
    <>
      <div className="keen-slider__slide">
        <Grid container direction="column">
          <Grid item xs={12}>
            <Poster src={`https://image.tmdb.org/t/p/w200${mediaItem.posterPath}`} alt="poster"></Poster>
            {allowRequest === true && (
              <RequestStatusContainer
                mediaItem={mediaItem}
                buttonType={RequestButtonType.ICONBUTTON}
                buttonSx={{
                  padding: '0px',
                  position: 'absolute',
                  bottom: '1px',
                  right: '1px',
                  left: '83px',
                  top: '147px',
                  height: '32px',
                  width: '32px'
                }}
                displaySx={{
                  position: 'absolute',
                  bottom: '1px',
                  right: '0px',
                  left: '-1px',
                  top: '147px',
                  height: '32px',
                  width: '115px'
                }}
                spinnerSx={{
                  position: 'absolute',
                  bottom: '1px',
                  right: '0px',
                  left: '0px',
                  top: '140px',
                  height: '32px',
                  width: '115px'
                }}
              ></RequestStatusContainer>
            )}
          </Grid>
          <Grid item xs={10}>
            <MediaItemText mediaItem={mediaItem} />
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default MobileMediaItemPoster
