import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import CustomRequestButton from '../customRequest/CustomRequestButton'

/* eslint-disable @typescript-eslint/no-empty-function */
const NoResultsFound = () => {
  const onRequestSubmitted = () => {}

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item>
        <Typography variant="body2">Unfortunately, we can't find any results</Typography>
      </Grid>
      <Grid item style={{ marginLeft: '5px' }}>
        <CustomRequestButton onRequestSubmitted={onRequestSubmitted} />
      </Grid>
    </Grid>
  )
}

export default NoResultsFound
