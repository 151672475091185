import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { SxProps, Theme } from '@mui/material/styles'
import Spinner from './Spinner'

type LoadingButtonProps = {
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  id: string
  children?: React.ReactNode
  sx?: SxProps<Theme>
  isLoading?: boolean
  isLoadingContent?: string
}

const LoadingButton = (props: LoadingButtonProps) => {
  return (
    <Stack direction="row">
      <Button
        variant="contained"
        disabled={props.disabled}
        id={props.id}
        type="submit"
        onClick={props.onClick}
        sx={props.sx}
      >
        {props.isLoading ? (
          <>
            {props.isLoadingContent}
            <Box>
              <Spinner></Spinner>
            </Box>
          </>
        ) : (
          props.children
        )}
      </Button>
    </Stack>
  )
}

export default LoadingButton
