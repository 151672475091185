import { useMutation } from 'react-query'
import { AxiosError } from 'axios'
import { apiClient } from '../services/apiClient'
import endpoints from '../services/apiEndpoints'
import { ApiError } from '../types/ApiError'
import { UserNotification } from '../types/UserNotification'

const updateUserNotification = async (userNotification: UserNotification): Promise<UserNotification> => {
  const response = await apiClient.put<number>(
    endpoints.users.updateNotifications,
    {
      notificationType: userNotification.notificationType,
      isIncluded: userNotification.isIncluded
    },
    {
      withCredentials: true
    }
  )
  return userNotification
}

export const useUpdateUserNotification = ({
  onSuccess,
  onError
}: {
  onError: (message: string) => void
  onSuccess: (notificationUpdated: UserNotification) => void
}) => {
  return useMutation(
    async (userNotification: UserNotification): Promise<UserNotification> => {
      return await updateUserNotification(userNotification)
    },
    {
      onSuccess: (data) => {
        if (onSuccess) {
          onSuccess(data)
        }
      },
      onError: (error: AxiosError) => {
        const errorDetails = error.response!.data as ApiError
        if (onError) {
          onError(errorDetails.errors[0].message)
        }
      }
    }
  )
}
