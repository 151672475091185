const routes = {
  wildcard: '*',
  root: '/',
  home: '/home',
  profile: '/profile',
  searchResults: {
    route: '/search-results/:searchTerm',
    partial: '/search-results'
  },
  requests: '/requests',
  admin: '/admin',
  logout: '/logout',
  login: '/login',
  error: '/error'
}

export default routes
