import React from 'react'
import styled from '@emotion/styled'
import Typography from '@mui/material/Typography'
import CustomRequestButton from './CustomRequestButton'

const CustomRequestContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  gap: 20px;
`

interface CustomRequestProps {
  onRequestSubmitted: () => void
}

const CustomRequest = ({ onRequestSubmitted }: CustomRequestProps) => {
  return (
    <CustomRequestContainer>
      <Typography>Couldn't find what you were looking for?</Typography>
      <CustomRequestButton onRequestSubmitted={onRequestSubmitted} />
    </CustomRequestContainer>
  )
}

export default CustomRequest
