import React from 'react'
import styled from '@emotion/styled'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { SxProps } from '@mui/material/styles'
import MediaTypes from '../../types/MediaTypes'
import { ToggleGroupItem } from '../../types/ToggleGroupItem'

interface MediaTypeSelectorProps {
  onToggleChanged: (event: React.MouseEvent<HTMLElement>, value: MediaTypes) => void
  sx: SxProps
}

const StyledToggleButton = styled(ToggleButton)(() => ({
  '&.MuiToggleButton-root': {
    textTransform: 'capitalize',
    width: '95px',
    borderRadius: 0,
    border: '2px solid black',
    backgroundColor: '#262626',
    '&:first-of-type': {
      borderTopLeftRadius: '40px',
      borderBottomLeftRadius: '40px'
    },
    '&:last-of-type': {
      borderTopRightRadius: '40px',
      borderBottomRightRadius: '40px'
    },
    '&:hover': {
      backgroundColor: '#DDA144'
    }
  },
  '&.Mui-selected': {
    backgroundColor: 'rgba(213, 137, 21)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(213, 137, 21)'
    }
  }
}))

const toggleButtonGroupItems: ToggleGroupItem[] = [
  {
    displayName: 'Movie',
    value: MediaTypes.Movie
  },
  {
    displayName: 'TV Show',
    value: MediaTypes.TvShow
  }
]

const MediaTypeSelector = ({ onToggleChanged, sx }: MediaTypeSelectorProps) => {
  const [selectedItem, setSelectedItem] = React.useState(MediaTypes.Movie)

  const handleToggleChanged = (event: React.MouseEvent<HTMLElement>, value: MediaTypes) => {
    setSelectedItem(value)
    onToggleChanged(event, value)
  }

  return (
    <ToggleButtonGroup
      color="primary"
      value={selectedItem}
      exclusive
      onChange={handleToggleChanged}
      aria-label="Platform"
      sx={sx}
    >
      {toggleButtonGroupItems.map((item) => (
        <StyledToggleButton key={item.value} value={item.value}>
          {item.displayName}
        </StyledToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

export default MediaTypeSelector
