import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { useFlags } from 'launchdarkly-react-client-sdk'
import TitleWithDivider from '../components/TitleWithDivider'
import AdminRequestsTable from '../components/adminRequests/AdminRequestsTable'
import content from '../data/content'
import { useAdminAllRequests } from '../hooks/useAdminAllRequests'
import routes from '../routes'

const componentContent = content.pages.admin

const StyledTitleWithDiver = styled(TitleWithDivider)`
  margin-top: 20px;
`

const AdminPage = () => {
  const { feCanAccessAdminPanel } = useFlags()
  const navigate = useNavigate()
  const { data, isLoading, refetch, isFetching } = useAdminAllRequests()

  if (!feCanAccessAdminPanel) {
    navigate(routes.home)
  }

  const handleMediaRequestUpdated = async () => {
    await refetch()
  }

  return (
    <div>
      <StyledTitleWithDiver
        title={componentContent.title}
        titleCssProperties={{ paddingTop: '20px', marginBottom: '24px' }}
        displaySearchViewType={false}
      />
      <div style={{ padding: '10px 20px 20px 20px' }}>
        <AdminRequestsTable
          isLoading={isLoading || isFetching}
          mediaItems={data}
          onMediaRequestUpdated={handleMediaRequestUpdated}
        />
      </div>
    </div>
  )
}

export default AdminPage
