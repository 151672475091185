import React from 'react'
import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import theme from '../../../data/theme'
import LargeTextComponent from '../../LargeText'

export interface SearchListViewItemTitleProps {
  title: string
  year?: number
  overview?: string
}

const StyledTitle = styled.div`
  padding-top: 5px;
  padding-left: 5px;
`

const StyledOverview = styled.div`
  padding-top: 5px;
  padding-left: 5px;
`

const SearchListViewItemTitle = ({ title, year, overview }: SearchListViewItemTitleProps) => {
  const isExtraSmallDevice = useMediaQuery(theme.breakpoints.only('xs'))
  const smallDevices = useMediaQuery(theme.breakpoints.only('sm'))
  const mediumUpToLarge = useMediaQuery(theme.breakpoints.between('md', 'lg'))

  let titleMaxLength = 100
  let overviewMaxLength = 390
  if (isExtraSmallDevice) {
    titleMaxLength = 25
    overviewMaxLength = 110
  } else if (smallDevices) {
    titleMaxLength = 45
    overviewMaxLength = 190
  } else if (mediumUpToLarge) {
    titleMaxLength = 65
    overviewMaxLength = 280
  }

  return (
    <Grid container item direction="column" style={{ height: '100%' }}>
      <Grid item xs={3}>
        <StyledTitle>
          <LargeTextComponent variant="h4" text={title} maxLength={titleMaxLength} />
        </StyledTitle>
      </Grid>
      {year && (
        <Grid item xs={1}>
          <StyledTitle>
            <Typography variant="body2">{year ? `(${year})` : ''}</Typography>
          </StyledTitle>
        </Grid>
      )}
      <Grid
        item
        xs={6}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        }}
      >
        <StyledOverview>
          <LargeTextComponent text={overview || ''} maxLength={overviewMaxLength} />
        </StyledOverview>
      </Grid>
    </Grid>
  )
}

export default SearchListViewItemTitle
