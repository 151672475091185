import React from 'react'
import styled from '@emotion/styled'
import GridView from '@mui/icons-material/GridView'
import List from '@mui/icons-material/List'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { ToggleGroupItem } from '../../types/ToggleGroupItem'

interface SearchViewTypeSelectProps {
  onToggleButtonGroupChange?: (event: React.MouseEvent<HTMLElement>, selectedItem: string) => void
}

const StyledToggleButton = styled(ToggleButton)(() => ({
  '&.MuiToggleButton-root': {
    textTransform: 'capitalize',
    width: '50px',
    borderRadius: 0,
    border: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#DDA144'
    }
  },
  '&.Mui-selected': {
    backgroundColor: 'rgba(213, 137, 21)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(213, 137, 21)'
    }
  }
}))

const toggleButtonGroupItems: ToggleGroupItem[] = [
  { displayName: 'List', value: 'List', icon: List },
  { displayName: 'Grid', value: 'Grid', icon: GridView }
]

const SearchViewTypeSelect = ({ onToggleButtonGroupChange }: SearchViewTypeSelectProps) => {
  const [selectedItem, setSelectedItem] = React.useState('List')

  const onToggleChanged = (event: React.MouseEvent<HTMLElement>, newSelectedItem: string) => {
    setSelectedItem(newSelectedItem)
    if (onToggleButtonGroupChange) {
      onToggleButtonGroupChange(event, newSelectedItem)
    }
  }

  return (
    <div style={{ marginRight: '20px' }}>
      <ToggleButtonGroup
        color="primary"
        value={selectedItem}
        exclusive
        onChange={onToggleChanged}
        aria-label="Platform"
        sx={{ marginBottom: '15px', marginLeft: '10px' }}
      >
        {toggleButtonGroupItems.map((item) => (
          <StyledToggleButton key={item.value} value={item.value}>
            {item.icon && <item.icon />}
          </StyledToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  )
}

export default SearchViewTypeSelect
