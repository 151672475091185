import { useQuery } from 'react-query'
import { apiClient } from '../services/apiClient'
import endpoints from '../services/apiEndpoints'
import { MediaItemDetailsAggregate } from '../types/MediaItemDetails'

const fetchUpcomingMovies = async (): Promise<MediaItemDetailsAggregate> => {
  const response = await apiClient.get<MediaItemDetailsAggregate>(endpoints.upcoming.movies, {
    withCredentials: true
  })
  return response.data
}

export const useUpcomingMovies = () => {
  const { data, isLoading, error, isSuccess } = useQuery<MediaItemDetailsAggregate, Error>(
    'upcomingMovies',
    fetchUpcomingMovies,
    {
      enabled: true,
      refetchOnWindowFocus: false
    }
  )
  return { data, isLoading, error, isSuccess }
}
