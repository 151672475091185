import React, { CSSProperties, useState } from 'react'
import styled from '@emotion/styled'
import ControlPointSharpIcon from '@mui/icons-material/ControlPointSharp'
import Chip, { ChipProps } from '@mui/material/Chip'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import { SxProps, useTheme } from '@mui/material/styles'
import { useSnackbar } from 'notistack'
import { useSendMediaRequest } from '../../hooks/useSendMediaRequest'
import { MediaItemDetails } from '../../types/MediaItemDetails'
import RequestButtonType from '../../types/RequestButtonType'
import Spinner from '../loading/Spinner'

interface StyledRequestIconButtonProps extends IconButtonProps {
  borderColor: string
}

const StyledRequestIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'borderColor'
})<StyledRequestIconButtonProps>`
  border: 1px solid ${({ borderColor }) => borderColor};
  margin-bottom: 5px;
  margin-left: 5px;
  padding: 2px;
  opacity: 0.9;

  svg {
    font-size: 30px;
  }
`

interface StyledRequestChipProps extends ChipProps {
  borderColor: string
}

const StyledRequestChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'borderColor'
})<StyledRequestChipProps>`
  border: 1px solid ${({ borderColor }) => borderColor};
  margin-bottom: 5px;
  margin-left: 5px;
`

const SpinnerContainer = styled.div`
  margin-bottom: 8px;
`

interface RequestStatusButtonProps {
  mediaItem: MediaItemDetails
  buttonType: RequestButtonType
  onRequestChanged: () => void
  buttonSx?: SxProps
  spinnerSx?: CSSProperties
}

const RequestStatusButton = ({
  mediaItem,
  buttonType,
  onRequestChanged,
  buttonSx,
  spinnerSx
}: RequestStatusButtonProps) => {
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleSendMediaRequestSuccess = () => {
    setTimeout(() => {
      setIsLoading(false)
      enqueueSnackbar(`Successfully requested ${mediaItem.title}`, { variant: 'success' })
      onRequestChanged()
    }, 1000)
  }

  const handleSendMediaRequestError = (message: string) => {
    setTimeout(() => {
      enqueueSnackbar(message, { variant: 'error' })
      setIsLoading(false)
    }, 1000)
  }

  const { mutate } = useSendMediaRequest({
    onSuccess: handleSendMediaRequestSuccess,
    onError: handleSendMediaRequestError
  })

  const handleRequestClick = () => {
    setIsLoading(true)
    mutate({
      title: mediaItem.title,
      year: mediaItem.year,
      mediaType: mediaItem.mediaType,
      theMovieDbId: mediaItem.id.toString(),
      posterPath: mediaItem.posterPath,
      backdropPath: mediaItem.backdropPath
    })
  }

  if (isLoading) {
    return (
      <SpinnerContainer style={spinnerSx}>
        <Spinner />
      </SpinnerContainer>
    )
  }

  if (buttonType === RequestButtonType.CHIP) {
    return (
      <StyledRequestChip
        borderColor={theme.palette.common.black}
        label="Request"
        variant="filled"
        icon={<ControlPointSharpIcon />}
        onClick={handleRequestClick}
      />
    )
  } else {
    return (
      <>
        <StyledRequestIconButton
          borderColor={theme.palette.common.black}
          onClick={handleRequestClick}
          size="small"
          sx={buttonSx}
        >
          <ControlPointSharpIcon />
        </StyledRequestIconButton>
      </>
    )
  }
}

export default RequestStatusButton
