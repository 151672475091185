import React from 'react'
import WorkerFerret from '../components/WorkerFerret'

const ErrorPage = () => {
  return (
    <div>
      <WorkerFerret />
    </div>
  )
}

export default ErrorPage
