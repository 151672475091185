import React from 'react'
import Typography from '@mui/material/Typography'
import { MediaItemDetails } from '../../../types/MediaItemDetails'

interface SubTitleProps {
  mediaItem: MediaItemDetails
}

const subTitleText = (mediaItem: MediaItemDetails): string => {
  if (!mediaItem.seasonNumber && !mediaItem.episodeNumber) {
    return ''
  }

  let subTitle = mediaItem.tvShowTitle

  if (mediaItem.seasonNumber && !mediaItem.episodeNumber) {
    subTitle += ` Season ${mediaItem.seasonNumber}`
  }

  if (mediaItem.seasonNumber && mediaItem.episodeNumber) {
    subTitle += ` S${mediaItem.seasonNumber} - E${mediaItem.episodeNumber}`
  }

  return subTitle || ''
}

const SubTitle = ({ mediaItem }: SubTitleProps) => {
  return <Typography>{subTitleText(mediaItem)}</Typography>
}

export default SubTitle
