import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import DeleteIcon from '@mui/icons-material/InfoOutlined'
import Alert from '@mui/material/Alert'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/Icon'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import LoginBackground from '../components/LoginBackground'
import TextInput from '../components/input/TextInput'
import LoadingButton from '../components/loading/LoadingButton'
import content from '../data/content'
import theme from '../data/theme'
import { useLogin } from '../hooks/useLogin'
import routes from '../routes'
import { LoginForm } from '../types/LoginForm'
import { getImageUrl } from '../utils/images'

const FormBackground = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 490px;
  background-color: ${theme.palette.common.loginWrapperColor};
  border-radius: 1.25rem;
  padding: 2rem 3rem 2rem 3rem;
`

const SignInOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 2rem;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-bottom: 1rem;
  }
`

const FerretFlixTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`

const FerretFlixTitle = styled.img`
  height: 110%;
  width: 110%;
`

const RememberMe = styled.div`
  margin-bottom: 0.5rem;

  @media (min-width: ${theme.breakpoints.values.md}px) {
    margin-bottom: 0;
  }
`

const TooltipStyled = styled(Tooltip)`
  overflow: visible;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
`

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
`

const componentContent = content.pages.login

const LoginPage = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const [hasLoginFailed, setHasLoginFailed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const onLoginFailure = () => {
    setHasLoginFailed(true)
    setIsLoading(false)
  }

  const onLoginSuccess = () => {
    navigate(routes.home)
  }

  const requestLoginMutation = useLogin({
    username,
    password,
    rememberMe,
    onError: onLoginFailure,
    onSuccess: onLoginSuccess
  })

  const onSubmit = () => {
    setIsLoading(true)
    setHasLoginFailed(false)
    requestLoginMutation.mutate()
  }

  const form = useForm<LoginForm>({
    mode: 'onChange',
    defaultValues: {
      username: '',
      password: ''
    }
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = form

  return (
    <div style={{ position: 'relative', height: '100vh', width: '100%' }}>
      <LoginBackground />
      <FormBackground>
        <FerretFlixTitleContainer>
          <FerretFlixTitle src={getImageUrl('FerretFlix_Title-min.png')} alt="FerretFlix Title" />
        </FerretFlixTitleContainer>
        <Typography variant="h2" gutterBottom data-cy="login-title">
          <TooltipContainer data-cy="tooltip-container">
            {componentContent.title}
            <TooltipStyled
              title={componentContent.tooltip}
              arrow
              placement="right-start"
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -8]
                    }
                  }
                ]
              }}
            >
              <IconButton>
                <DeleteIcon sx={{ color: theme.palette.primary.main }} />
              </IconButton>
            </TooltipStyled>
          </TooltipContainer>
        </Typography>
        {hasLoginFailed && (
          <Alert severity="error" data-cy="login-failed-message">
            {componentContent.errors.loginFailed}
          </Alert>
        )}
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextInput
              register={register}
              name="username"
              type="username"
              showLabel={false}
              placeholder={componentContent.usernamePlaceholder}
              error={errors.username}
              value={username}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value)}
              validation={{
                required: componentContent.errors.username.required,
                minLength: {
                  value: 5,
                  message: componentContent.errors.username.minLength
                },
                maxLength: {
                  value: 150,
                  message: componentContent.errors.username.maxLength
                }
              }}
            ></TextInput>
            <TextInput
              register={register}
              name="password"
              type="password"
              showLabel={false}
              placeholder={componentContent.passwordPlaceholder}
              error={errors.password}
              value={password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
              validation={{
                required: componentContent.errors.password.required,
                minLength: {
                  value: 5,
                  message: componentContent.errors.password.minLength
                },
                maxLength: {
                  value: 150,
                  message: componentContent.errors.password.maxLength
                }
              }}
            ></TextInput>
            <SignInOptionsContainer>
              <RememberMe data-cy="remember-me">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRememberMe(event.target.checked)}
                    />
                  }
                  label={componentContent.rememberMe}
                  labelPlacement="start"
                />
              </RememberMe>
              <Link
                href={componentContent.forgotPasswordLink}
                color="inherit"
                underline="hover"
                target="_blank"
                rel="noreferrer noopener"
                data-cy="forgot-password-link"
              >
                {componentContent.forgotPassword}
              </Link>
            </SignInOptionsContainer>
            <LoadingButton id="submit-sign-in-button" isLoading={requestLoginMutation.isLoading || isLoading}>
              {componentContent.submitButtonTitle}
            </LoadingButton>
          </form>
        </div>
      </FormBackground>
    </div>
  )
}

export default LoginPage
