import React from 'react'
import styled from '@emotion/styled'
import MediaTypes from '../../../types/MediaTypes'
import { getBackdropUrl } from '../../../utils/images'

interface SearchListViewItemContainerProps {
  backdropHeight: string
  backdropPath: string
  children?: React.ReactNode
}

const MediaContainer = styled.div`
  background-image: url('${(props: SearchListViewItemContainerProps) => props.backdropPath}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: calc(100% - 0px) calc(20% - 20px);
  height: ${(props: SearchListViewItemContainerProps) => props.backdropHeight};
  position: relative;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }
`

const ChildrenContainer = styled.div`
  position: relative;
`

const SearchListViewItemContainer = ({ backdropHeight, backdropPath, children }: SearchListViewItemContainerProps) => {
  return (
    <MediaContainer backdropHeight={backdropHeight} backdropPath={backdropPath}>
      <ChildrenContainer>{children}</ChildrenContainer>
    </MediaContainer>
  )
}

export default SearchListViewItemContainer
