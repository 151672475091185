import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { useLogout } from '../hooks/useLogout'
import routes from '../routes'
import { getImageUrl } from '../utils/images'

const LogOutContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const StyledFerretImage = styled.img`
  width: 250px;
  height: 250px;
`

const LogoutPage = () => {
  const navigate = useNavigate()

  const onLogoutError = () => {
    navigate(routes.error)
  }

  const onLogoutSuccess = () => {
    navigate(routes.login)
  }

  const requestLoginMutation = useLogout({
    onError: onLogoutError,
    onSuccess: onLogoutSuccess
  })

  setTimeout(() => {
    requestLoginMutation.mutate()
  }, 2500)

  return (
    <LogOutContainer>
      <StyledFerretImage src={getImageUrl(`Logging_Out_250x250.gif`)} alt="Logging out"></StyledFerretImage>
    </LogOutContainer>
  )
}

export default LogoutPage
