import React from 'react'
import styled from '@emotion/styled'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { MediaItemDetails } from '../../../types/MediaItemDetails'
import RequestButtonType from '../../../types/RequestButtonType'
import { getBackdropUrl } from '../../../utils/images'
import LargeTextComponent from '../../LargeText'
import RequestStatusContainer from '../../requestStatus/RequestStatusContainer'
import SubTitle from './SubTitle'

interface DesktopMediaItemDetailProps {
  mediaItem: MediaItemDetails
  backdropPath: string
  allowRequest?: boolean
}

const DesktopMediaItemDetailContainer = styled.div`
  background-image: url('${(props: DesktopMediaItemDetailProps) => props.backdropPath}');
  background-position: calc(100% - 0px) calc(20% - 20px);
  background-size: cover;
  background-repeat: no-repeat;
  height: 258px;
  position: relative;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
  }
`

const MediaItemPoster = styled.img`
  height: 186px;
  width: 122px;
`

const MediaItemDetail = ({ mediaItem, allowRequest = true }: DesktopMediaItemDetailProps) => {
  return (
    <>
      <DesktopMediaItemDetailContainer
        mediaItem={mediaItem}
        backdropPath={getBackdropUrl(mediaItem.mediaType, mediaItem.backdropPath)}
        className="keen-slider__slide"
        style={{ marginBottom: '15px', borderRadius: '20px', paddingRight: '20px' }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ padding: '35px 20px 20px 25px', position: 'relative', zIndex: 1 }}
        >
          <Grid item xs={2} sx={{ paddingLeft: '10px' }}>
            <MediaItemPoster
              src={`https://image.tmdb.org/t/p/w200${mediaItem.posterPath}`}
              alt="poster"
            ></MediaItemPoster>
          </Grid>
          <Grid
            container
            item
            xs={10}
            direction="column"
            justifyContent="flex-start"
            alignItems="left"
            sx={{ background: 'rgba(0, 0, 0, 0.6)', borderRadius: '20px', padding: '15px' }}
          >
            <Grid item xs={2} sx={{ marginBottom: '10px' }}>
              <Typography variant="h2">
                {mediaItem.title}
                {` `}
                {mediaItem.year ? `(${mediaItem.year})` : ''}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ marginBottom: '10px' }}>
              {allowRequest === true ? (
                <RequestStatusContainer mediaItem={mediaItem} buttonType={RequestButtonType.CHIP} />
              ) : (
                <SubTitle mediaItem={mediaItem} />
              )}
            </Grid>
            <Grid item xs={3}>
              <LargeTextComponent maxLength={500} text={mediaItem.overview} />
            </Grid>
          </Grid>
        </Grid>
      </DesktopMediaItemDetailContainer>
    </>
  )
}

export default MediaItemDetail
